<template>
  <v-dialog
    v-if="namespace === module"
    v-model="dialog"
    :hide-overlay="dialog ? false : true"
    persistent
    max-width="80%"
  >
    <router-view name="modal" />
  </v-dialog>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: { type: Boolean, required: true },
    module: { type: String, required: false, default: "dashboard" }
  },
  computed: {
    namespace() {
      return this.$route.meta.namespace || "";
    }
  }
};
</script>
